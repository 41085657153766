import { ref, computed } from "vue"

import { defineStore } from "pinia"

import * as settingsApi from "@/api/settingsApi.js"

import { worldLayers, nationalLayers } from "@/constants/maps.js"

import { useGlobalStore } from "@/stores/global.js"

export const useMapsStore = defineStore(
  "maps",
  () => {
    const mapSettings = ref({})
    const availableMapSettings = ref([])

    const localMapSettings = ref({
      linesDefaultColor: null,
      markersDefaultColor: null,
      markersSecondaryDefaultColor: null,
      selectedMarkersDefaultColor: null,
      linesDefaultThickness: null,
      markersDefaultStyle: null,
      markersSecondaryDefaultStyle: null,
      markersDefaultSize: null,
      markersSecondaryDefaultSize: null,
      imageLayersDefaultOpacity: null,
      mapInfoOverlaysOpacity: null,
      polygonsDefaultOpacity: null,
      defaultApplyPolygonColorToDesign: null,
      defaultShowPolygonDesign: null,
      mouseControlDefaultProjection: null,
      mouseControlDefaultUseDegrees: null
    })

    const localProfileSettings = ref({
      profileDefaultColor: null,
      antennasDefaultColor: null,
      fresnelEllipseDefaultColor: null,
      fresnelRayDefaultColor: null,
      profileLinesDefaultThickness: null
    })

    const defaultLayer = computed(() => {
      const globalStore = useGlobalStore()
      const availableLayers = globalStore.globalConfig.onlineMapLayers
      const defaultLayer = mapSettings.value.defaultOnlineMapLayer || "AerialWithLabels"

      if (availableLayers.length === 0) {
        return null
      }
      return availableLayers.find((layer) => layer === defaultLayer) || availableLayers[0]
    })

    const fetchConfigs = async () => {
      const [mapSettingsData, availableMapSettingsData] = await Promise.all([settingsApi.getMapSettings(), settingsApi.getAvailableImageMapSettingsForUser()])

      mapSettings.value = mapSettingsData
      availableMapSettings.value = availableMapSettingsData
    }

    const setLocalMapSettings = () => {
      Object.keys(localMapSettings.value).forEach((key) => {
        if (localMapSettings.value[key] === null) {
          localMapSettings.value[key] = mapSettings.value[key]
        }
      })
    }

    const outputMapSettings = computed(() => {
      const output = {}
      Object.keys(mapSettings.value).forEach((key) => {
        output[key] = localMapSettings.value[key] !== null ? localMapSettings.value[key] : mapSettings.value[key]
      })
      return output
    })

    const outputProfileSettings = computed(() => {
      const output = {}
      Object.keys(mapSettings.value).forEach((key) => {
        output[key] = localProfileSettings.value[key] !== null ? localProfileSettings.value[key] : mapSettings.value[key]
      })
      return output
    })

    const setLocalProfileSettings = () => {
      Object.keys(localProfileSettings.value).forEach((key) => {
        if (localProfileSettings.value[key] === null) {
          localProfileSettings.value[key] = mapSettings.value[key]
        }
      })
    }

    const setMapSettings = async (settings) => {
      await settingsApi.setMapSettings(settings)
      await fetchConfigs()
    }

    const getAvailableLayersFromLayers = (layers, forAdmin) => {
      const globalStore = useGlobalStore()

      layers.forEach((layer) => {
        const isMapSettingAvailable = mapSettings.value.onlineMapLayers.includes(layer.value)
        const isGlobalConfigAvailable = globalStore.globalConfig.onlineMapLayers.includes(layer.value)
        if (isGlobalConfigAvailable && (forAdmin || isMapSettingAvailable)) {
          layer.selected = forAdmin && isMapSettingAvailable
          layer.available = true
        } else {
          layer.available = false
        }
      })

      return layers.filter((layer) => layer.available)
    }

    const getAvailableWorldLayers = (forAdmin) => getAvailableLayersFromLayers(worldLayers, forAdmin)

    const getAvailableNationalLayers = (forAdmin) => getAvailableLayersFromLayers(nationalLayers, forAdmin)

    const getAllAvailableLayers = (forAdmin) => [...getAvailableWorldLayers(forAdmin), ...getAvailableNationalLayers(forAdmin)]

    return {
      mapSettings,
      localMapSettings,
      localProfileSettings,
      outputMapSettings,
      outputProfileSettings,
      availableMapSettings,
      setMapSettings,
      fetchConfigs,
      getAllAvailableLayers,
      getAvailableWorldLayers,
      getAvailableNationalLayers,
      defaultLayer,
      setLocalMapSettings,
      setLocalProfileSettings
    }
  },
  {
    persist: {
      storage: window.localStorage,
      paths: ["localMapSettings", "localProfileSettings"]
    }
  }
)
