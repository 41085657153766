import { ref } from "vue"
import { defineStore } from "pinia"

import * as moduleSettingsApi from "@/api/moduleSettingsApi.js"

export const useModuleDataStore = defineStore("moduleData", () => {
  const moduleConfig = ref({})
  const moduleSettings = ref({})

  const fetchData = async () => {
    const [moduleConfigData, moduleSettingsData] = await Promise.all([moduleSettingsApi.getModuleConfiguration(), moduleSettingsApi.getModuleSettings()])

    moduleConfig.value = moduleConfigData
    moduleSettings.value = moduleSettingsData
  }

  const getModulePrivateSettings = () => moduleSettingsApi.getModulePrivateSettings()

  const setModulePrivateSettings = async (settings) => {
    await moduleSettingsApi.setModulePrivateSettings(settings)
    await getModulePrivateSettings()
  }

  const setModuleSettings = async (settings) => {
    await moduleSettingsApi.setModuleSettings(settings)
    await fetchData()
  }

  const buildOrderedList = ({ definitionsArr, userSettingsArr, getDefinitionKey, getSettingsKey }) => {
    // 1) Create a map from settings -> {..., index}
    const settingsMap = new Map(
      userSettingsArr.map((settingItem, index) => {
        const key = getSettingsKey(settingItem)
        return [
          key,
          {
            ...settingItem,
            index
          }
        ]
      })
    )

    // 2) Merge definitions with the settings
    const merged = definitionsArr.map((defItem) => {
      const key = getDefinitionKey(defItem)
      const relatedSetting = settingsMap.get(key) || {}

      return {
        ...defItem,
        ...relatedSetting,
        // If the setting isn't found, push to the end:
        index: relatedSetting.index ?? Number.MAX_SAFE_INTEGER,
        // If you want to ensure 'selected' is always a boolean, for example:
        selected: relatedSetting.selected ?? false
      }
    })

    // 3) Sort by index
    merged.sort((a, b) => a.index - b.index)

    // 4) Strip out index (or any other internal property you don’t want)
    /* eslint-disable-next-line no-unused-vars */
    return merged.map(({ index, ...rest }) => rest)
  }

  return { moduleConfig, moduleSettings, fetchData, setModuleSettings, getModulePrivateSettings, setModulePrivateSettings, buildOrderedList }
})
