<template>
  <div class="row gap-1">
    <q-icon v-if="show" size="sm" name="info" color="primary">
      <!-- eslint-disable -->
      <q-tooltip class="bg-primary" v-html="value" />
    </q-icon>
    <app-button v-if="relatedAttachmentData" size="sm" icon="attach_file" dense @click="showAttachmentDialog = true" />
    <app-file-viewer-dialog v-if="showAttachmentDialog" :attachment="relatedAttachmentData" @ok="showAttachmentDialog = null" />
  </div>
</template>

<script setup>
import { ref, computed } from "vue"

import { useGlobalStore } from "@/stores/global.js"
import { useModuleDataStore } from "@/stores/moduleData.js"

import i18n from "@/plugins/i18n.js"

import DOMPurify from "dompurify"

import AppFileViewerDialog from "@/components/commons/AppFileViewerDialog.vue"

const globalStore = useGlobalStore()
const moduleStore = useModuleDataStore()

const props = defineProps({
  identifier: String,
  translationKey: String
})

const showAttachmentDialog = ref(false)

const keyForIdentifier = computed(() => props.identifier || props.translationKey)
const keyForTranslation = computed(() => props.translationKey || props.identifier)

const show = computed(
  () => globalStore.globalConfig.settings.informationButtons && moduleStore.moduleSettings.enabledInformationButtons.includes(keyForIdentifier.value)
)

const value = computed(() => DOMPurify.sanitize(i18n.global.t(keyForTranslation.value)))

const relatedAttachmentData = computed(() =>
  moduleStore.moduleSettings.informationButtonsAttachementsMap?.find((item) => item.informationButton === keyForIdentifier.value)
)
</script>
