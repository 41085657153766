<template>
  <app-dialog :show-cancel="false" :min-width="dialogMinWidth" min-height="600px">
    <template #title>
      {{ nameComputed }}
    </template>
    <template #content>
      <div style="height: 560px">
        <app-file-viewer :file-path="nameComputed" :file-src="srcForAttachment" />
      </div>
    </template>
  </app-dialog>
</template>

<script setup>
import { computed } from "vue"

import { useSessionStore } from "@/stores/session.js"

import { getModuleForRoute } from "@/plugins/router.js"

import { useQuasar } from "quasar"

import AppFileViewer from "@/components/commons/AppFileViewer.vue"

const props = defineProps({
  attachment: Object
})

const $q = useQuasar()

const dialogMinWidth = computed(() => ($q.screen.gt.md ? "1200px" : $q.screen.gt.md ? "800px" : "100%"))

const modulePathForInformationButtonAttachment = computed(() => {
  const currentModule = getModuleForRoute()

  if (currentModule === "freqExperimentationRequests") {
    return "/api/freqExperimentationRequests/documentation/getDocument"
  }

  if (currentModule === "comsis") {
    return "/api/comsis/comsisDocumentation/getDocument"
  }

  if (currentModule === "mwParity") {
    return "/api/mwParity/documentation/getDocument"
  }

  if (currentModule === "main") {
    return "/api/main/documentation/getDocument"
  }

  return null
})

const nameComputed = computed(() => {
  return props.attachment.name || props.attachment.attachmentName
})

const srcForAttachment = computed(() => {
  if (!modulePathForInformationButtonAttachment.value) {
    return null
  }

  return `${modulePathForInformationButtonAttachment.value}?name=${nameComputed.value}&access_token=${useSessionStore().accessToken}`
})
</script>
