<template>
  <q-form ref="form" greedy no-error-focus>
    <fieldset :disabled="disable" :readonly="readonly" :class="fieldsetClass">
      <slot />
    </fieldset>
  </q-form>
</template>

<script setup>
import { ref } from "vue"

const props = defineProps({
  disable: Boolean,
  readonly: Boolean,
  fieldsetClass: String
})

const form = ref(null)

defineExpose({
  validate: () => {
    if (props.disable) {
      return true
    }
    return form.value.validate()
  },
  resetValidation: () => {
    return form.value.resetValidation()
  }
})
</script>

<style lang="scss" scoped>
fieldset {
  border: none;
  padding: 0;

  &[disabled] {
    // Opacity will be set for children elements individually
    opacity: 1 !important;
  }
}
</style>
