import { createRouter, createWebHistory } from "vue-router"

import { useTranslationsStore } from "@/stores/translations.js"
import { useGlobalStore } from "@/stores/global.js"
import { useModuleDataStore } from "@/stores/moduleData.js"
import { useSessionStore } from "@/stores/session.js"
import { useAppStateStore } from "@/stores/appState.js"

import * as quasarUtils from "@/services/quasarUtils.js"
import * as modulesService from "@/services/main/modulesService.js"

import { routes, routeNames } from "@/constants/routes.js"

const router = createRouter({
  history: createWebHistory("/app/"),
  routes
})

const moduleFromUrlToModuleName = (path) => path?.replace(/-([a-z])/g, (g) => g[1].toUpperCase())

//this is not reactive because of location.pathname!
export const getModuleForRoute = () => {
  const moduleName = location.pathname.split("/")[2]
  return moduleName ? moduleFromUrlToModuleName(moduleName) : "main"
}

export const getModuleForRouteReactive = (route) => {
  if (!route) {
    return getModuleForRoute()
  }
  const moduleName = route.path.split("/")[1]
  return moduleName ? moduleFromUrlToModuleName(moduleName) : "main"
}

let lastModule = null
let returnTo = null

const nonAuthRoutes = [routeNames.mainLogin, routeNames.mainChangePassword, routeNames.mainAccount, routeNames.mainUrlViewer, routeNames.mwParityMain]

const getRouteForAdmin = () => {
  const moduleDataStore = useModuleDataStore()

  switch (moduleDataStore.moduleSettings.adminStartPathId) {
    case "modules":
      return { name: routeNames.mainModules }
    case "helpPage":
      return { name: routeNames.mainHelp }
    case "documentation":
      return { name: routeNames.mainDocumentation }
    case "modulesSettings":
      return { name: routeNames.mainModules, params: { mode: "settings" } }
    default:
      return { name: routeNames.mainModules }
  }
}

const getRouteForUser = () => {
  const moduleDataStore = useModuleDataStore()

  if (moduleDataStore.moduleSettings.userStartModuleId) {
    const moduleInfo = modulesService.getModuleInfo(moduleDataStore.moduleSettings.userStartModuleId)
    return modulesService.getToAndHrefFromModuleInfo(moduleInfo, false)
  }

  switch (moduleDataStore.moduleSettings.userStartPathId) {
    case "modules":
      return { to: { name: routeNames.mainModules } }
    case "helpPage":
      return { to: { name: routeNames.mainHelp } }
    case "documentation":
      return { to: { name: routeNames.mainDocumentation } }
    default:
      return { to: { name: routeNames.mainModules } }
  }
}

export const defaultRedirect = ({ skipReturnTo } = {}) => {
  try {
    if (!skipReturnTo && returnTo) {
      router.push(returnTo)
    } else {
      const sessionStore = useSessionStore()
      if (sessionStore.isAdmin) {
        router.push(getRouteForAdmin())
      } else {
        const { href, to } = getRouteForUser()
        if (href) {
          window.location.href = href
        } else {
          router.push(to)
        }
      }
    }
  } catch (err) {
    console.error(err)
  }
}

const moduleNameInGlobalConfig = (moduleName) => {
  if (moduleName === "equipments") {
    return "trRxEquipment"
  }

  return moduleName
}

router.beforeEach((to, from, next) => {
  quasarUtils.hideLoading()
  const sessionStore = useSessionStore()
  const globalStore = useGlobalStore()

  const nextModule = to.path === "/" ? "main" : moduleFromUrlToModuleName(to.path.split("/")[1])

  if (nextModule !== "main" && !globalStore.globalConfig.modules[moduleNameInGlobalConfig(nextModule)]) {
    console.error(`Module ${nextModule} not found in global config`)
    next({ name: routeNames.mainLogin })
    return
  }

  if (!sessionStore.session && !nonAuthRoutes.includes(to.name)) {
    returnTo = to
    next({ name: routeNames.mainLogin })
    return
  }

  if (to.name !== routeNames.mainLogin) {
    returnTo = null
  }

  next()
})

router.afterEach(async (to, from, failure) => {
  if (failure) {
    //Can happen if there is aborted navigation
    //Eg happens on login page because initial navigation is from root to login
    //And also App.vue redirects to login
    return
  }
  const nextModule = getModuleForRoute()
  const translationsStore = useTranslationsStore()
  const moduleDataStore = useModuleDataStore()
  const appStateStore = useAppStateStore()

  if (lastModule !== nextModule) {
    appStateStore.setChangingModule(true)
    await moduleDataStore.fetchData()
    await translationsStore.fetchTranslations()
  }
  lastModule = nextModule

  appStateStore.setChangingModule(false)
})

export default router
