<template>
  <div>
    <app-dialog v-if="showMessagesDialog" :show-cancel="false" @ok="onMessagesDialogClose">
      <template #title>
        <div v-if="!forcedLogoutMode">{{ $t("_RSFAP_SHARED_SUPERVISION_NOTIFICATIONS_MODAL_HEADER") }}</div>
        <div v-else>{{ $t("_RSFAP_SHARED_SUPERVISION_NOTIFICATIONS_FORCED_LOGOUOT_MODAL_HEADER") }}</div>
      </template>
      <template #content>
        <div v-for="item in items" :key="item.id">{{ `[${item.date}]: ${item.message}` }}</div>
      </template>
    </app-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue"

import { useAppStateStore } from "@/stores/appState.js"
import { useSessionStore } from "@/stores/session.js"

import eventBus from "@/plugins/eventBus.js"

import * as dates from "@/services/dates.js"

import { useQuasar } from "quasar"

import i18n from "@/plugins/i18n.js"

import debounce from "lodash/debounce"

const sessionStore = useSessionStore()

const $q = useQuasar()
const items = ref([])
const showMessagesDialog = ref(false)
const wasClosed = ref(false)
const numbersOfUnreadMessages = ref(0)
const forcedLogoutMode = ref(false)
let notification

const appStateStore = useAppStateStore()

const onMessagesDialogClose = () => {
  wasClosed.value = true
  showMessagesDialog.value = false
  notification = $q.notify({
    group: false,
    message: "Messages",
    position: "bottom-right",
    timeout: 0,
    actions: [
      {
        label: "Open",
        color: "accent",
        handler() {
          showMessagesDialog.value = true
          numbersOfUnreadMessages.value = 0
        }
      }
    ]
  })
}

const handleNotification = (message) => {
  items.value.push({
    date: dates.getTimeStringFromDate(new Date()),
    message,
    id: items.value.length
  })

  if (!wasClosed.value) {
    showMessagesDialog.value = true
  } else {
    numbersOfUnreadMessages.value++
    notification({ message: `Messages (${numbersOfUnreadMessages.value})` })
  }
}

const handleForcedLogout = (message) => {
  forcedLogoutMode.value = true
  handleNotification(message)
  setTimeout(() => {
    sessionStore.logOut()
  }, 10000)
}

const handleNextMaintenance = (data) => {
  if (appStateStore.nextMaintenanceIgnoredIds.includes(data.Id)) {
    return
  }

  const message = i18n.global.t("_RSFAP_SHARED_NEXT_MAINTENANCE", [data.StartDate, data.Reason])
  handleNotification(message)
  appStateStore.addIgnoredId(data.Id)
}

const resetNotifications = () => {
  items.value = []
  showMessagesDialog.value = false
  wasClosed.value = false
  forcedLogoutMode.value = false
  if (notification) {
    notification()
  }
}

const debounceResizeEvent = debounce(() => {
  eventBus.$emit("window-resize")
}, 100)

onMounted(() => {
  eventBus.$on("ws-adminNotificationReceive", handleNotification)
  eventBus.$on("ws-forceLogoutReceive", handleForcedLogout)
  eventBus.$on("ws-receiveNextMaintenance", handleNextMaintenance)
  eventBus.$on("before-log-out", resetNotifications)

  window.addEventListener("resize", debounceResizeEvent)
})

onUnmounted(() => {
  eventBus.$off("ws-adminNotificationReceive", handleNotification)
  eventBus.$off("ws-forceLogoutReceive", handleForcedLogout)
  eventBus.$off("ws-receiveNextMaintenance", handleNextMaintenance)
  eventBus.$off("before-log-out", resetNotifications)

  window.removeEventListener("resize", debounceResizeEvent)
})
</script>
