<template>
  <div>
    <q-toolbar :style="loginPageStyle">
      <slot name="left" />
      <div class="q-mr-xl q-ml-sm text-h6">
        <span>{{ $t("_RSFAP_SHARED_HEADER_BRAND_FIRST_LETTER") }}</span>
        <span>{{ $t("_RSFAP_SHARED_HEADER_BRAND_SECOND_PART") }}</span>
      </div>
      <template v-if="isLoggedIn">
        <app-button :to="{ name: routeNames.mainModules }" route-match-accent-color="header-accent" text-color="white" flat>
          {{ $t("_RSFAP_SHARED_HEADER_MODULES") }}
        </app-button>
        <app-button :to="{ name: routeNames.mainHelp }" route-match-accent-color="header-accent" text-color="white" flat>
          {{ $t("_RSFAP_SHARED_HEADER_HELP") }}
        </app-button>
        <app-button :to="{ name: routeNames.mainDocumentation }" route-match-accent-color="header-accent" text-color="white" flat>
          {{ $t("_RSFAP_SHARED_HEADER_DOCUMENTATION") }}
        </app-button>
      </template>
      <q-space />
      <div>{{ globalStore.globalConfig.appVersion }}</div>
      <div v-if="isAdmin" class="row items-center gap-1 q-mx-md">
        <app-button
          icon="settings"
          round
          flat
          route-match-accent-color="header-accent"
          text-color="white"
          :to="{ name: routeNames.mainModules, params: { mode: 'settings' } }"
        />
        <app-button icon="visibility" round flat route-match-accent-color="header-accent" text-color="white" :to="{ name: routeNames.mainSupervision }" />
        <app-button
          v-if="showStats"
          icon="bar_chart"
          round
          flat
          route-match-accent-color="header-accent"
          text-color="white"
          :to="{ name: routeNames.mainStatDashboard }"
        />
      </div>
      <app-button :loading="changingLanguage" flat text-color="white">
        <div class="row items-center gap-2">
          <div>{{ currentLanguage }}</div>
          <app-country-flag :code="currentLanguage" />
        </div>
        <q-menu dark>
          <app-list>
            <q-item v-for="lang in supportedLanguages" :key="lang" clickable class="justify-center">
              <app-button :disable="changingLanguage" @click="translationsStore.setLanguage(lang)">
                <div class="row items-center gap-2">
                  <div class="text-bold">{{ lang }}</div>
                  <app-country-flag :code="lang" />
                </div>
              </app-button>
            </q-item>
          </app-list>
        </q-menu>
      </app-button>

      <app-button v-if="isLoggedIn" :label="displayName" icon-right="expand_more" flat text-color="white">
        <q-menu dark>
          <app-list>
            <q-item v-if="userInfo.origin === 'USERS' || userInfo.origin === 'USERS_CNTCT'" clickable>
              <app-button :to="{ name: routeNames.mainAccountEdit, params: { mode: userInfo.id } }" :label="$t('_RSFAP_SHARED_HEADER_ACCOUNT')" />
            </q-item>
            <q-item clickable class="justify-center">
              <app-button :label="$t('_RSFAP_SHARED_HEADER_LOGOUT')" @click="sessionStore.logOut" />
            </q-item>
          </app-list>
        </q-menu>
      </app-button>
    </q-toolbar>
    <q-toolbar v-if="secondaryToolbar" :style="loginPageStyle" class="toolbar-secondary">
      <div v-if="secondaryToolbar.title" class="q-mr-xl q-ml-sm text-h8">
        {{ secondaryToolbar.title }}
      </div>
      <app-button
        v-for="option in secondaryToolbar.options"
        :key="option.label"
        :label="option.label"
        route-match-accent-color="header-accent"
        :to="option.to"
        flat
        size="sm"
        text-color="white"
      />
    </q-toolbar>
  </div>
</template>

<script setup>
import { computed } from "vue"
import { storeToRefs } from "pinia"

import { useRoute } from "vue-router"

import { useSessionStore } from "@/stores/session.js"
import { useGlobalStore } from "@/stores/global.js"
import { useTranslationsStore } from "@/stores/translations.js"
import { useModuleDataStore } from "@/stores/moduleData.js"

import { routeNames } from "@/constants/routes.js"
import { getModuleSubheader } from "@/constants/main/modules.js"

import AppCountryFlag from "@/components/commons/AppCountryFlag.vue"

import { getModuleForRouteReactive } from "@/plugins/router.js"

const sessionStore = useSessionStore()
const translationsStore = useTranslationsStore()
const globalStore = useGlobalStore()

const route = useRoute()

const { changingLanguage, currentLanguage } = storeToRefs(translationsStore)
const { moduleConfig } = storeToRefs(useModuleDataStore())

const showStats = computed(() => globalStore.globalConfig.modules.comsis && globalStore.globalSettings.enabledModules.includes("comsis"))

const loginPageStyle = computed(() => {
  return {
    backgroundColor: globalStore.globalSettings.loginPageTopBarBackgroundColor,
    color: `${globalStore.globalSettings.loginPageTopBarColor} !important`
  }
})

const secondaryToolbar = computed(() => getModuleSubheader(getModuleForRouteReactive(route)))

const supportedLanguages = computed(() => globalStore.globalSettings.enabledLanguages.filter((lang) => moduleConfig.value.supportedLanguages.includes(lang)))

const { isAdmin, isLoggedIn, userInfo, displayName } = storeToRefs(sessionStore)

const init = () => {
  const style = document.createElement("style")
  style.innerHTML = `.text-header-accent { color: ${globalStore.globalSettings.loginPageTopBarAccentColor} }`
  document.getElementsByTagName("head")[0].appendChild(style)
}

init()
</script>

<style lang="scss" scoped>
.toolbar-secondary {
  min-height: 38px !important;
  border-top: 1px solid white;
}
</style>
