<template>
  <q-checkbox ref="input" dense :rules="outputRules" :disable="disableComputed || readonlyComputed">
    <template v-for="(slot, name) in $slots" v-slot:[name]="scope">
      <slot :name="name" v-bind="scope ?? {}" />
    </template>
  </q-checkbox>
</template>

<script setup>
import { ref, computed } from "vue"

import { useValidators } from "@/composables/useValidators"

const validators = useValidators()

const props = defineProps({
  required: Boolean,
  disable: Boolean,
  readonly: Boolean
})

const input = ref(null)

const disableComputed = computed(() => props.disable || input.value?.$el.closest("fieldset")?.disabled)
const readonlyComputed = computed(() => props.readonly || input.value?.$el.closest("fieldset")?.attributes.readonly?.value)

const outputRules = computed(() => {
  const rules = props.rules ? [...props.rules] : []
  if (props.required) {
    rules.push(validators.required)
  }
  return rules
})
</script>
