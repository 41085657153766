<template>
  <q-radio ref="input" dense :disable="disableComputed || readonlyComputed" />
</template>

<script setup>
import { ref, computed } from "vue"

const props = defineProps({
  disable: Boolean,
  readonly: Boolean
})

const input = ref(null)
const disableComputed = computed(() => props.disable || input.value?.$el.closest("fieldset")?.disabled)
const readonlyComputed = computed(() => props.readonly || input.value?.$el.closest("fieldset")?.attributes.readonly?.value)
</script>
