export const routeNames = {
  root: "root",
  mainLogin: "mainLogin",
  mainModules: "mainModules",
  mainHelp: "mainHelp",
  mainDocumentation: "mainDocumentation",
  mainAdminPanel: "mainAdminPanel",
  mainChangePassword: "mainChangePassword",
  mainSupervision: "mainSupervision",
  mainStatDashboard: "mainStatDashboard",
  mainStatCharts: "mainStatCharts",
  mainAccount: "mainAccount",
  mainAccountEdit: "mainAccountEdit",
  mainMapTest: "mainMapTest",
  mainUrlViewer: "mainUrlViewer",

  mwParityRoot: "mwParityRoot",
  mwParityMain: "mwParityMain",
  mwParityAdminPanel: "mwParityAdminPanel",
  mwParityDocumentation: "mwParityDocumentation",

  mwApplicationsRequestsRoot: "mwApplicationsRequestsRoot",
  mwApplicationsRequestsMain: "mwApplicationsRequestsMain",
  mwApplicationsRequestsApplication: "mwApplicationsRequestsApplication",
  mwApplicationsRequestsConsultation: "mwApplicationsRequestsConsultation",
  mwApplicationsRequestsAdminPanel: "mwApplicationsRequestsAdminPanel",

  freqExperimentationRoot: "freqExperimentationRoot",
  freqExperimentationRequestsMain: "freqExperimentationRequestsMain",
  freqExperimentationRequestsAdminPanel: "freqExperimentationRequestsAdminPanel",
  freqExperimentationRequestsBandRequest: "freqExperimentationRequestsBandRequest",
  freqExperimentationRequestsRadarRequest: "freqExperimentationRequestsRadarRequest",
  freqExperimentationRequestsDroneRequest: "freqExperimentationRequestsDroneRequest",
  freqExperimentationRequestsTerrestialRequest: "freqExperimentationRequestsTerrestialRequest",
  freqExperimentationRequestsConsultation: "freqExperimentationRequestsConsultation",

  agencyControlMain: "agencyControlMain",
  agencyControlAdminPanel: "agencyControlAdminPanel",

  equipmentsMain: "equipmentsMain",
  equipmentsDrafts: "equipmentsDrafts",
  equipmentsApplication: "equipmentsApplication",
  equipmentsAdminPanel: "equipmentsAdminPanel"
}

export const routes = [
  {
    path: "/",
    name: routeNames.root,
    component: () => import("@/views/main/DefaultRedirectView.vue")
  },
  {
    path: "/main/login/:mode?",
    name: routeNames.mainLogin,
    component: () => import("@/views/main/LoginView.vue")
  },
  {
    path: "/main/modules/:mode?",
    name: routeNames.mainModules,
    component: () => import("@/views/main/ModulesView.vue")
  },
  {
    path: "/main/help-page",
    name: routeNames.mainHelp,
    component: () => import("@/views/main/HelpPageView.vue")
  },
  {
    path: "/main/documentation",
    name: routeNames.mainDocumentation,
    component: () => import("@/views/main/DocumentationView.vue")
  },
  {
    path: "/main/admin-panel/:tab?/:subtab?",
    name: routeNames.mainAdminPanel,
    component: () => import("@/views/main/AdminPanelView.vue")
  },
  {
    path: "/main/help-page",
    name: routeNames.mainHelp,
    component: () => import("@/views/main/HelpPageView.vue")
  },
  {
    path: "/main/passwords-management/:mode/:token?",
    name: routeNames.mainChangePassword,
    component: () => import("@/views/main/ChangePasswordView.vue")
  },
  {
    path: "/main/supervision",
    name: routeNames.mainSupervision,
    component: () => import("@/views/main/SupervisionView.vue")
  },
  {
    path: "/main/stat-dashboard",
    name: routeNames.mainStatDashboard,
    component: () => import("@/views/main/StatDashboardView.vue")
  },
  {
    path: "/main/stat-charts/:module/:year/:month",
    name: routeNames.mainStatCharts,
    component: () => import("@/views/main/StatChartsView.vue")
  },
  {
    path: "/main/account",
    name: routeNames.mainAccount,
    component: () => import("@/views/main/AccountView.vue")
  },
  {
    path: "/main/account/:mode",
    name: routeNames.mainAccountEdit,
    component: () => import("@/views/main/AccountView.vue")
  },
  {
    path: "/main/map-test",
    name: routeNames.mainMapTest,
    component: () => import("@/views/main/MapTestView.vue")
  },
  {
    path: "/main/url-viewer/:alias?",
    name: routeNames.mainUrlViewer,
    component: () => import("@/views/main/UrlViewerView.vue")
  },

  {
    path: "/mw-parity",
    name: routeNames.mwParityRoot,
    redirect() {
      return { name: routeNames.mwParityMain }
    }
  },
  {
    path: "/mw-parity/view",
    name: routeNames.mwParityMain,
    component: () => import("@/views/mw-parity/MainView.vue")
  },
  {
    path: "/mw-parity/admin-panel/:tab?/:subtab?",
    name: routeNames.mwParityAdminPanel,
    component: () => import("@/views/mw-parity/AdminPanelView.vue")
  },
  {
    path: "/mw-parity/documentation",
    name: routeNames.mwParityDocumentation,
    component: () => import("@/views/mw-parity/DocumentationView.vue")
  },

  {
    path: "/mw-applications-requests",
    name: routeNames.mwApplicationsRequestsRoot,
    redirect() {
      return { name: routeNames.mwApplicationsRequestsMain }
    }
  },
  {
    path: "/mw-applications-requests/start-page",
    name: routeNames.mwApplicationsRequestsMain,
    component: () => import("@/views/mw-applications-requests/MainView.vue")
  },
  {
    path: "/mw-applications-requests/application/:id?",
    name: routeNames.mwApplicationsRequestsApplication,
    component: () => import("@/views/mw-applications-requests/ApplicationView.vue")
  },
  {
    path: "/mw-applications-requests/consultation/:id/:tab?/:subtab?",
    name: routeNames.mwApplicationsRequestsConsultation,
    component: () => import("@/views/mw-applications-requests/ConsultationView.vue")
  },
  {
    path: "/mw-applications-requests/admin-panel/:tab?/:subtab?",
    name: routeNames.mwApplicationsRequestsAdminPanel,
    component: () => import("@/views/mw-applications-requests/AdminPanelView.vue")
  },

  {
    path: "/freq-experimentation-requests",
    name: routeNames.freqExperimentationRoot,
    redirect() {
      return { name: routeNames.freqExperimentationRequestsMain }
    }
  },
  {
    path: "/freq-experimentation-requests/requests-list",
    name: routeNames.freqExperimentationRequestsMain,
    component: () => import("@/views/freq-experimentation-requests/MainView.vue")
  },
  {
    path: "/freq-experimentation-requests/admin-panel/:tab?/:subtab?",
    name: routeNames.freqExperimentationRequestsAdminPanel,
    component: () => import("@/views/freq-experimentation-requests/AdminPanelView.vue")
  },
  {
    path: "/freq-experimentation-requests/request/band/:id/:requestType?/:testType?",
    name: routeNames.freqExperimentationRequestsBandRequest,
    component: () => import("@/views/freq-experimentation-requests/BandRequestView.vue")
  },
  {
    path: "/freq-experimentation-requests/request/band/:id/:requestType?/:testType?",
    name: routeNames.freqExperimentationRequestsBandRequest,
    component: () => import("@/views/freq-experimentation-requests/BandRequestView.vue")
  },
  {
    path: "/freq-experimentation-requests/request/radar/:id/:requestType?/:testType?",
    name: routeNames.freqExperimentationRequestsRadarRequest,
    component: () => import("@/views/freq-experimentation-requests/RadarRequestView.vue")
  },
  {
    path: "/freq-experimentation-requests/request/drone/:id/:requestType?/:testType?",
    name: routeNames.freqExperimentationRequestsDroneRequest,
    component: () => import("@/views/freq-experimentation-requests/DroneRequestView.vue")
  },
  {
    path: "/freq-experimentation-requests/request/terrestial/:id/:requestType?/:testType?",
    name: routeNames.freqExperimentationRequestsTerrestialRequest,
    component: () => import("@/views/freq-experimentation-requests/TerrestialRequestView.vue")
  },
  {
    path: "/freq-experimentation-requests/consult/:service/:id/:tab?/:subtab?",
    name: routeNames.freqExperimentationRequestsConsultation,
    component: () => import("@/views/freq-experimentation-requests/ConsultationView.vue")
  },

  {
    path: "/agency-control/view",
    name: routeNames.agencyControlMain,
    component: () => import("@/views/agency-control/MainView.vue")
  },
  {
    path: "/agency-control/admin-panel/:tab?/:subtab?",
    name: routeNames.agencyControlAdminPanel,
    component: () => import("@/views/agency-control/AdminPanelView.vue")
  },

  {
    path: "/equipments/existing-equipments",
    name: routeNames.equipmentsMain,
    component: () => import("@/views/equipments/MainView.vue")
  },
  {
    path: "/equipments/drafts-list",
    name: routeNames.equipmentsDrafts,
    component: () => import("@/views/equipments/DraftsListView.vue")
  },
  {
    path: "/equipments/application/:id?",
    name: routeNames.equipmentsApplication,
    component: () => import("@/views/equipments/ApplicationView.vue")
  },
  {
    path: "/equipments/admin-panel/:tab?/:subtab?",
    name: routeNames.equipmentsAdminPanel,
    component: () => import("@/views/equipments/AdminPanelView.vue")
  },
  {
    path: "/equipments",
    redirect() {
      return { name: routeNames.equipmentsMain }
    }
  }
]
